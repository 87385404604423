<template>
  <div class="search-container">
    <my-header :isSenior.sync="isSenior"/>
    <div class="content">
      <div class="left">
        <div class="top">
          <div class="title" v-if="$route.query.type == 0">省市志</div>
          <div class="title" v-else-if="$route.query.type == 1">县市志</div>
          <div class="title" v-else>乡镇志</div>
          <!-- 省 -->
          <div class="province">
            <div class="province_title">
              <div class="head">省级志书</div>
            </div>
            <div class="name_box">
              <span
                v-show="$route.query.type != 2"
                class="name"
                :class="{ active: provinceCode === '4535' }"
                @click="onChangeProvince('4535', '福建省')"
                >福建</span
              >
              <!-- @dblclick="onDbClick" -->
              <span
                class="name"
                :class="{ active: provinceCode === '4566' }"
                @click="onChangeProvince('4566', '台湾省')"
                >台湾</span
              >
              <!-- @dblclick="onDbClick" -->
            </div>
          </div>
          <!-- 市 -->
          <div class="city" v-show="cityList.length || disList.length">
            <div class="city_title">
              <div class="head">市县志书</div>
              <div
                class="more"
                v-show="isShowCityMore"
                @click.stop="onMore(cityCode)"
              >
                更多>>
              </div>
            </div>
            <div class="name_box" ref="city" @click="onchangeCity">
              <!-- @dblclick="onDbClick" -->
              <span
                class="name"
                :class="{ active: cityCode === v.code }"
                :data-code="v.code"
                data-status="city"
                :data-name="v.name"
                v-for="v in cityList"
                :key="v.id"
                >{{ v.name }}</span
              >
              <br />
              <div
                v-show="provinceCode === '4535' && disList.length"
                style="border-top: 1px dashed #e0d0b6"
              >
                <span
                  class="name"
                  style="font-size: 12px"
                  :data-code="v2.code"
                  data-status="dis"
                  v-for="v2 in disList"
                  :class="{ active: disCode === v2.code }"
                  :key="v2.id"
                  :data-name="v2.name"
                >
                  {{ v2.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">
          <span>{{ area }}</span>
          <span class="info"
            >共{{ total }}条信息
            <span v-show="total"
              >第{{ PageIndex | toChinesNum }}页/共{{
                (total / PageSize) | toChinesNum
              }}页</span
            ></span
          >
        </div>
        <div v-if="seniorList.length">
          <div
            class="item"
            v-for="v in seniorList"
            :key="v.id"
            @click="onClick(v.sourceID)"
          >
            <div class="top">{{ v.title }}</div>
            <div class="middle">
              <div class="publish">
                <span class="editor overstep" :title="v.contributor"
                  >责任者：{{ v.contributor }}</span
                >
                <span class="press overstep" :title="v.publisher"
                  >出版单位：{{ v.publisher }}</span
                >
              </div>
              <div class="edition">
                <span class="editor overstep" :title="v.notes"
                  >一般性附注：{{ v.notes }}</span
                >
                <span class="press overstep" :title="v.dateOfPublication"
                  >出版日期：{{ v.dateOfPublication }}</span
                >
              </div>
            </div>
            <div class="bottom overstep" :title="v.seriesArea">
              丛编题名：{{ v.seriesArea }}
            </div>
          </div>
          <div class="paging">
            <el-pagination
              :page-size="PageSize"
              @current-change="onCurrentChange"
              :current-page.sync="PageIndex"
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="empty" style="margin-top: 200px" v-else>
          <el-empty
            :image="require('../../assets/image/empty.png')"
            description="暂无数据"
          ></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { getAreas, seniorSearch, getCity, getDis } from "@/api/literature.js";
import myHeader from "@/components/header/index.vue";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      groundingAreas: [],
      provinceCode: "4535",
      proName: "福建省",
      cityCode: "",
      townCode: "",
      cityList: [],
      disList: [],
      tempDisList: [],
      disCode: "",
      disName: "",
      isShowCityMore: false,
      PageIndex: 1,
      PageSize: 10,
      total: 0,
      seniorList: [],
      area: "福建省",
      flag: false,
      Dynasty: "",
      cityArea: "",
      cityName: "",
    };
  },
  async created() {
    if (this.$route.query.type == 2) {
      this.provinceCode = "4566";
      this.proName = "台湾省";
      this.area = "台湾省";
    }
    await this.getAreas(this.$route.query.type);
    // if (Object.keys(this.$route.params).length) {
    //   localStorage.setItem("params", JSON.stringify(this.$route.params));
    // }
    // var params = JSON.parse(localStorage.getItem("params"));
    // this.area = this.$route.query?.name;
    // this.provinceCode = this.$route.query?.provinceCode;
    // this.townCode = this.disCode = params?.townCode;
    // this.flag = params?.flag;
    // this.Dynasty = this.$route.query?.Dynasty;
    // this.proName = this.area.split("/")[0];
    // if (this.area.split("/").length === 1) {
    //   this.proName = this.area.split("/")[0];
    // } else if (
    //   this.area.split("/").length === 2 ||
    //   this.area.split("/").length === 3
    // ) {
    //   this.cityCode = params?.cityCode;
    //   this.proName = this.area.split("/")[0];
    //   this.cityName = this.area.split("/")[1];
    // }

    await this.getCity();

    this.onSeniorSearch();
  },
  filters: {
    toChinesNum(size) {
      var num = size;
      if ((num + "").indexOf(".") !== -1) {
        num = parseInt(num) + 1;
      }
      let changeNum = [
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
      ];
      let unit = ["", "十", "百", "千", "万"];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split("").reverse();
        let newNum = "";
        let newArr = [];
        strArr.forEach((item, index) => {
          newArr.unshift(
            item === "0" ? changeNum[item] : changeNum[item] + unit[index]
          );
        });
        let numArr = [];
        newArr.forEach((m, n) => {
          if (m !== "零") numArr.push(n);
        });
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === "零") {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m;
              }
            } else {
              newNum += m;
            }
          });
        } else {
          newNum = newArr[0];
        }

        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = "0" + noWan;
      }
      return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
    },
  },
  methods: {
    findCommonElements(arr1, arr2) {
      const common = [];
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          if (arr1[i].name === arr2[j]) {
            common.push(arr1[i]);
            break; // 找到一个匹配的元素就可以退出当前循环
          }
        }
      }
      return common;
    },
    async getAreas(type) {
      try {
        const res = await getAreas({ type });
        this.groundingAreas = res;
      } catch (error) {
        console.log(error);
      }
    },
    onClick(sourceID) {
      let routeData = this.$router.resolve({
        name: "Result",
        query: {
          sourceID,
          type: this.$route.query.type,
        },
      });
      window.open(routeData.href, "_blank");
    },

    click(name) {
      if (name === this.Dynasty) {
        this.Dynasty = "";
      } else {
        this.Dynasty = name;
      }
      this.PageIndex = 1;
      this.onSeniorSearch();
    },

    onCurrentChange(index) {
      this.PageIndex = index;
      this.onSeniorSearch();
    },

    async onSeniorSearch() {
      const res = await seniorSearch({
        PageIndex: this.PageIndex,
        PageSize: this.PageSize,
        Area: this.area,
        type: this.$route.query.type,
      });
      this.total = res.totalCount;
      this.seniorList = res.items;
      if (res.totalCount === 0) return this.$message.info("暂无数据");
      console.log(res, "res");
    },

    // 获取市县
    async getCity() {
      const res = await getCity({ ParentCode: this.provinceCode });
      if (!this.flag) {
        // this.cityCode = res[0].code;
        // this.cityName = res[0].name;
        // this.getDis(this.cityCode);
      }
      this.flag = false;
      this.cityList = this.findCommonElements(res, this.groundingAreas);
    },
    // 获取县
    async getDis(code) {
      if (RegExp(/4566/).exec(code)) return;
      const res = await getDis({ ParentCode: code });
      this.tempDisList = res;
      if (res.length >= 15) {
        this.disList = this.findCommonElements(
          res.slice(0, 15),
          this.groundingAreas
        );
        this.isShowCityMore = true;
      } else {
        this.disList = this.findCommonElements(res, this.groundingAreas);
        this.isShowCityMore = false;
      }
    },
    // 切换省
    async onChangeProvince(code, name) {
      if (code === this.provinceCode && this.proName === name) {
        this.provinceCode = "";
        this.proName = "";
        this.cityCode = "";
        this.cityName = "";
        this.townCode = "";
        this.disCode = "";
        this.disName = "";
        this.area = "";
      } else {
        this.provinceCode = code;
        this.proName = name;
        this.area = name;
        this.cityCode = "";
        this.cityName = "";
        this.townCode = "";
        this.disCode = "";
        this.disName = "";
        await this.getCity();
        this.PageIndex = 1;
        this.onSeniorSearch();
      }
      if (this.provinceCode === "4566") this.disList = [];
    },
    // 切换城市
    onchangeCity(e) {
      if (e.target.nodeName === "SPAN" && e.target.dataset.code) {
        if (e.target.dataset.status === "city") {
          if (
            this.cityCode === e.target.dataset.code &&
            this.cityName === e.target.dataset.name
          ) {
            this.cityCode = "";
            this.cityName = "";
            this.disCode = "";
            this.disName = "";
            this.area = this.proName;
          } else {
            if (this.provinceCode === "")
              return this.$message.info("请先选择省份！");
            this.cityCode = e.target.dataset.code;
            this.cityName = e.target.dataset.name;
            this.area = this.proName + "/" + this.cityName;
            this.getDis(this.cityCode);
          }
          this.PageIndex = 1;
          this.onSeniorSearch();
        } else {
          if (
            this.disCode === e.target.dataset.code &&
            this.disName === e.target.dataset.name
          ) {
            this.disCode = "";
            this.disName = "";
            this.area = this.proName + "/" + this.cityName;
          } else {
            if (this.cityCode === "") return this.$message.info("请先选择市");
            this.disCode = e.target.dataset.code;
            this.disName = e.target.dataset.name;
            this.area = this.proName + "/" + this.cityName + "/" + this.disName;
          }
          this.PageIndex = 1;
          this.onSeniorSearch();
        }
      }
    },
    // 切换村镇
    onChangeTown(e) {
      if (e.target.nodeName === "SPAN" && e.target.dataset.code) {
        this.townCode = e.target.dataset.code;
      }
    },
    // 展开
    onMore(code) {
      this.$refs.city.style.height = "480px";
      this.$refs.city.style.overflow = "auto";
      this.disList = this.disList = this.findCommonElements(
        this.tempDisList,
        this.groundingAreas
      );
      this.isShowCityMore = false;
    },
  },
  beforeDestroy() {
    localStorage.removeItem("params");
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.active {
  color: #edb122 !important;
}
.search-container {
  .content {
    min-height: calc(100vh - 300px);
    background: rgba(201, 199, 199, 0.1);

    padding-top: 30px;
    display: flex;
    justify-content: center;
    padding: 20px;
    .left {
      // margin-left: 310px;
      width: 260px;
      .top {
        background: #fff;
        .title {
          text-align: center;
          line-height: 48px;
          width: 260px;
          height: 48px;
          background-color: #e0d0b6;
          font-size: 16px;
          color: #776259;
        }
        .province_title,
        .city_title,
        .village_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #dd1524;
          height: 48px;
          padding: 0 10px;
        }
        .head:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #dd1524;
          vertical-align: middle;
          margin-right: 7px;
          border-radius: 4px;
        }
        .more {
          cursor: pointer;
          color: #776259;
        }
        .province,
        .city,
        .village {
          .name_box {
            scrollbar-width: none;
            -ms-overflow-style: none;
            padding-left: 20px;
            background: #faf7f3;
            font-size: 14px;
            .name {
              display: inline-block;
              cursor: pointer;
              line-height: 48px;
              color: #776259;
              padding-right: 15px;
            }
          }
        }
      }
      .bottom {
        margin-top: 40px;
        .title {
          text-align: center;
          line-height: 48px;
          width: 260px;
          height: 48px;
          background-color: #e0d0b6;
          font-size: 16px;
          color: #776259;
        }
        .list {
          li {
            cursor: pointer;
            line-height: 48px;
            text-align: center;
            color: #776259;
            border-bottom: 1px solid #e8e8e8;
          }
        }
      }
    }
    .right {
      width: 1000px;
      margin-left: 40px;
      .title {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        line-height: 48px;
        font-size: 16px;
        color: #776259;
        background: #e0d0b6;
        .info {
          font-size: 14px;
        }
      }
      .item {
        cursor: pointer;
        background: #fff;
        height: 160px;
        margin-top: 20px;
        padding: 20px 0 0 26px;

        .overstep {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .top {
          font-size: 16px;
          color: #000;
          font-weight: 700;
        }
        .middle {
          font-size: 14px;
          color: #696969;
          margin-top: 20px;

          .publish {
            display: flex;
            justify-content: space-between;
            .press,
            .editor {
              width: 50%;
            }
          }
          .press {
            margin-left: 70px;
          }
          .edition {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .press,
            .editor {
              width: 50%;
            }
          }
        }
        .bottom {
          font-size: 14px;
          color: #696969;
          margin-top: 26px;
        }
      }
      ::v-deep .paging {
        display: flex;
        justify-content: center;
        margin-top: 88px;
        li {
          width: 30px;
          height: 30px;
          background: #ececef;
          color: #2b2b2b;
        }
        li:not(.disabled).active {
          background: #3e74ae;
        }
      }
    }
  }
}
</style>
  